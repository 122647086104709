<template>
<!-- 设备导入 -->
<div class="setting_main_bg">
  <div class="flexAC">
    <div class="settingAdd" @click="handleDownload"><span>设备模板</span></div>
    <div class="settingAdd" @click="handleDTUDownload"  style="margin-left: 20px;"><span>DTU模板</span></div>
  </div>
  <div class="import_card">
    <el-form :model="addfrom" :rules="formRules" ref="addFrom" class="gyDialog">
      <el-form-item label="厂家选择"  prop="manufacturer">
        <el-select v-model="addfrom.manufacturer" placeholder="请选择设备厂家" style="width: 100%">
          <el-option v-for="item in manufacturerList" :key="item.index"
            :label="item.datavalue"
            :value="item.dictid">
            {{ item.datavalue }}</el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="附件上传" prop="filepath">
        <div class="upload_box">
          <img src="../../../assets/img/up.png"/>
          <p class="ub_p1">点击或将文件拖拽到这里上传</p>
          <p class="ub_p2">支持扩展名：.xlsx .xls</p>
          <el-upload
            class="file-input"
            drag
            :action="action"
            :headers="headers"
            :show-file-list="false"
            :before-upload="beforeAvatarUpload"
            :on-success="handleAvatarSuccess">
          </el-upload>
        </div>
      </el-form-item>
    </el-form>
    <div class="fileBox " style="height: 20px" >
      <div class="flexCB" v-if="upSuccess">
        <p><span class="iconfont iconfujian"></span><span class="upFileName">{{ fileName }}</span></p>
        <span class="el-icon-circle-close upIco" @click="upDelete"></span>
      </div>
    </div>
    <div class="flexCE" style="margin-top: 60px">
      <div class="settingConfirm" @click="FromConfirm">确定</div>
    </div>
  </div>
</div>
</template>

<script>
import { nounSearch } from '@/http/api/settingSystem'
import { importEquipInfo } from '@/http/api/equip'
export default {
  name: 'import',
  data () {
    return {
      manufacturerList: [],
      action: this.BASE_URL + this.$url.PATH.annex,
      multiple: false,
      addfrom: {
        manufacturer: '',
        filepath: ''
      },
      formRules: {
        manufacturer: [
          { required: true, trigger: 'blur', message: '请选择厂家' }
        ],
        filepath: [
          { required: true, trigger: 'blur', message: '请上传附件' }
        ]
      },
      fileName: '',
      upSuccess: false
    }
  },
  computed: {
    headers () {
      return {
        Authorization: window.localStorage.getItem('token'),
        Loginsource: '1',
        Anotherplace: this.$url.PATH.Anotherplace
      }
    }
  },
  created () {
    this.getManu()
  },
  methods: {
    getManu () {
      nounSearch({ config: 'manufacturer' }).then(res => {
        if (res.status === '1') {
          this.manufacturerList = res.data
        } else {
          this.manufacturerList = []
        }
      })
    },
    handleDownload () {
      window.open(this.BASE_URL + this.$url.PATH.equipImport, '_blank')
    },
    handleDTUDownload () {
      window.open(this.BASE_URL + this.$url.PATH.DTUImport, '_blank')
    },
    beforeAvatarUpload (file) {
      const type = file.name.substring(file.name.lastIndexOf('.') + 1)
      const isJPG = 'xlsx/xls'.includes(type)
      const isLt50M = file.size / 1024 / 1024 < 50
      if (!isJPG) {
        this.$message.error('请上传支持的类型文件')
        return false
      } else if (!isLt50M) {
        this.$message.error('上传文件大小不能超过 50MB!')
        return false
      } else {
        this.fileName = file.name
        return isJPG && isLt50M
      }
    },
    handleAvatarSuccess (res) {
      if (res.status === '1') {
        this.upSuccess = true
        this.addfrom.filepath = res.data.filepath
      } else {
        this.upSuccess = false
        this.$message.error(res.message)
      }
    },
    upDelete () {
      this.addfrom.filepath = ''
      this.fileName = ''
      this.upSuccess = false
    },
    FromConfirm () {
      this.$refs.addFrom.validate((valid) => {
        if (!valid) return
        const params = {
          manufacturer: this.addfrom.manufacturer,
          filepath: this.addfrom.filepath
        }
        importEquipInfo(params).then(res => {
          if (res.status === '1') {
            this.$message.success(res.message)
          } else {
            this.$message.error(res.message)
          }
        })
      })
    }
  }
}
</script>

<style scoped>
.import_card {
  width: 600px;
  height: 540px;
  border: 2px dotted #A5B7CC;
  opacity: 1;
  border-radius: 8px;
  /*margin: 50px auto;*/
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -270px;
  margin-left: -300px;
  padding: 50px 100px;
  box-sizing: border-box;
}
.upload_box{
  width: 100%;
  height: 195px;
  background: #F6F9FF;
  border-radius: 4px;
  margin-top: 24px;
  padding: 40px 0px;
  box-sizing: border-box;
  text-align: center;
  position: relative;
}
.ub_p2{
  line-height: 22px;
  color: #A5B7CC;
}
.file-input{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 999;
}
.file-input >>> .el-upload {
  width: 100%;
  height: 100%;
}
.upFileName{
  margin-left: 5px;
  color: #333333;
  font-size: 14px;
}
.upIco{
  font-size: 16px;
  color: #FF4D4F;
  cursor: pointer;
}
</style>
